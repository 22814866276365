





























import Vue from 'vue'

export default Vue.extend({
  name: 'Input',
  data () {
    return {
      val: '',
      errorMessage: '',
      errorMessages: []
    }
  },
  props: {
    title: {
      type: String
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showLength: {
      type: Boolean,
      default: true
    },
    name: {
      type: String
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    type: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessageProp: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Placeholder text...'
    },
    validationRule: {
      type: String,
      required: false
    },
    validationRegexp: {
      type: String,
      required: false
    },
    minWidth: {
      type: [Number, String],
      default: 300
    },
    maxWidth: {
      type: [Number, String],
      default: 'auto'
    },
    default: {
      required: false,
      default: null
    },
    valueKey: {
      required: false,
      type: String
    },
    forbiddenRegexp: {
      required: false,
      type: String
    },
    inputClass: {
      required: false,
      type: String
    },
    value: {
      default: null
    },
    isCheckValue: {
      required: false,
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isCheckValue (check) {
      if (check) this.checkValue()
    },
    value (v) {
      this.val = v
    },
    val (v) {
      this.throwValue()
    }
  },
  mounted () {
    if (this.default) {
      this.val = this.default
    }
  },
  updated () {
    if (this.default) {
      //  this.val = this.default
    }
  },
  methods: {
    checkValue () {
      if (this.validationRule === 'email') {
        if (!this.validEmail(this.val)) {
          this.errorMessage = 'Email must be valid'
          this.$emit('error')
        }
      }
      if (this.validationRule === 'phone') {
        if (!this.validPhone(this.val)) {
          this.errorMessage = 'Phone must be valid'
          this.$emit('error')
        }
      }
      if (this.validationRule === 'url') {
        if (!this.validUrl(this.val) && this.val.length > 0) {
          this.errorMessage = 'Url must be valid'
        }
      }
      if (this.required && this.val.length === 0) {
        this.errorMessage = 'This field must not be empty'
        this.$emit('error')
      }

      if (this.validationRegexp) {
        const regexQuery = this.validationRegexp
        const test = new RegExp(regexQuery, 'i')
        if (!test.test(this.val)) {
          this.errorMessage = 'This field must be valid'
        }
      }
    },
    throwValue () {
      // off all errors
      this.errorMessage = ''
      // throw value of input
      if (this.forbiddenRegexp) {
        this.val = this.val.replace(new RegExp(this.forbiddenRegexp, 'gi'), '')
      }
      this.checkValue()
      this.$emit('changeValue', { value: this.val, validity: !this.errorMessage.length, key: this.valueKey })
    },
    validEmail (val: string) {
      const regexQuery = '^\\w+([\\+.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$'
      const email = new RegExp(regexQuery, 'i')
      return email.test(val)
    },
    validPhone (val: string) {
      const regexQuery = '^\\+?\\d{11,}$'
      const phone = new RegExp(regexQuery, 'i')
      return phone.test(val)
    },
    validUrl (val: string) {
      const regexQuery = '[a-z0-9-\\.]+\\.[a-z]{2,4}\\/?([^\\s<>\\#%"\\,\\{\\}\\\\|\\\\\\^\\[\\]`]+)?$'
      const url = new RegExp(regexQuery, 'i')
      return url.test(val)
    }
  }
})
