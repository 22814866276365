import { render, staticRenderFns } from "./AffiliateTable.vue?vue&type=template&id=289654a7&scoped=true&"
import script from "./AffiliateTable.vue?vue&type=script&lang=ts&"
export * from "./AffiliateTable.vue?vue&type=script&lang=ts&"
import style0 from "./AffiliateTable.vue?vue&type=style&index=0&id=289654a7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289654a7",
  null
  
)

export default component.exports