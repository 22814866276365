










































































































import Vue, { PropType } from 'vue'
import { IAffiliateTable } from '@/constants/Affiliate'
import ProfileAffiliateWithdrawalsModal from '@/components/profile/Affiliate/ProfileAffiliateWithdrawalsModal.vue'

export default Vue.extend({
  name: 'AffiliateTable',
  components: {
    ProfileAffiliateWithdrawalsModal
  },
  props: {
    table: {
      type: Object as PropType<IAffiliateTable>,
      required: true
    },
    perPage: {
      type: Number,
      default: 5
    },
    min_withdrawal: {
      type: Number,
      default: NaN
    }
  }
})
