import { render, staticRenderFns } from "./ProfileAffiliateWithdrawalsModal.vue?vue&type=template&id=44555b5d&scoped=true&"
import script from "./ProfileAffiliateWithdrawalsModal.vue?vue&type=script&lang=ts&"
export * from "./ProfileAffiliateWithdrawalsModal.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileAffiliateWithdrawalsModal.vue?vue&type=style&index=0&id=44555b5d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44555b5d",
  null
  
)

export default component.exports