























































import Modal from '@/components/Modal.vue'
import BaseInput from '@/components/BaseInput.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import AffiliateService from '@/services/api/AffiliateService'
import { mapActions } from 'vuex'

type Data = {
  requestSending: boolean
  wallets: Array<{
    label: string,
    value: string
  }>
  wallet: string
  amount_string: string
  wallet_data: string
}

export default {
  name: 'ProfileAffiliateWithdrawalsModal',
  components: {
    Modal,
    BaseInput,
    VSelect
  },
  props: {
    min_withdrawal: {
      type: Number,
      required: true
    }
  },
  data: (): Data => ({
    requestSending: false,
    wallets: [
      {
        label: 'Paypal',
        value: 'paypal'
      }
    ],
    wallet: 'paypal',
    amount_string: '',
    wallet_data: ''
  }),
  computed: {
    wallet_value: {
      get (): Data['wallets'][number] { return this.wallets.find(wallet => this.wallet === wallet.value) || null },
      set (value: Data['wallets'][number]): void { this.wallet = value.value }
    },
    amount (): number { return +this.amount_string },
    button_disabled (): boolean {
      return this.requestSending ||
        !this.wallet ||
        isNaN(this.amount) ||
        this.amount <= 0 ||
        !this.wallet_data ||
        !isFinite(this.min_withdrawal)
    },
    amountError (): string {
      if (!this.amount_string) {
        return ''
      }
      if (isNaN(this.amount)) {
        return 'Amount field must contain only numbers.'
      }
      if (this.amount < this.min_withdrawal) {
        return `The minimum payout under the referrer program is $${this.min_withdrawal}.`
      }
      return ''
    }
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    sendRequest (): void {
      this.requestSending = true
      AffiliateService.createWithdrawals({
        wallet: this.wallet,
        wallet_data: this.wallet_data,
        amount: this.amount
      })
        .then(() => {
          this.addNotification({ type: 'success', body: 'Success' })
          this.$modal.hide('profile-affiliate-withdrawals-modal')
          this.$emit('withdrawal-created')
        })
        .catch(({ response }) => {
          const { errors } = response.data
          for (const error of errors) {
            this.addNotification({ type: 'danger', body: error.detail.join(' ') })
          }
        })
        .finally(() => {
          this.requestSending = false
        })
    }
  }
}
